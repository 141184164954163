.Footer {
    margin: 0 6px 0 6px;
    padding: 10px;
    z-index: 300;
    background-color: #fff;
}

.Footer-social {
    margin-top: 4px;
    text-align: center;
}

.Footer-social-link {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.Footer-social-icon {
    font-size: 24px;
    margin-right: 6px;
}

.Footer-social-icon:hover {
    color: #bbb;
}

.Footer-copyright {
    font-weight: bold;
    margin: 4px 0;
    font-size: 12px;
    text-align: center;
}
