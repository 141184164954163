.SkillCard {
    width: 92px;
    height: 70px;
    margin: 10px 5px;
    color: #333;
    box-shadow: 0 4px 8px 0 rgba(44, 0, 85, 0.2);
    border-radius: 6px;
    background-color: #fff;
    position: relative;
    padding: 15px 24px;
}

.SkillCard:hover {
    box-shadow: 0 4px 8px 0 rgba(3, 146, 255, 0.4);
    background-color: rgba(3, 146, 255, 0.05);
    cursor: pointer;
}

.SkillCard-img {
    object-fit: contain;
    width: 92px;
    height: 70px;
}

.SkillCard-categories {
    width: 32px;
    max-height: 100px;
    position: absolute;
    top: 4px;
    right: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SkillCard-backend,
.SkillCard-frontend,
.SkillCard-mobile,
.SkillCard-database,
.SkillCard-wrench {
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    margin-top: 2px;
}

.SkillCard-backend {
    background-color: rgb(44, 0, 85);
}

.SkillCard-frontend {
    background-color: rgb(199, 0, 57);
}

.SkillCard-mobile {
    background-color: rgb(0, 108, 48);
}

.SkillCard-wrench {
    background-color: rgb(255, 195, 0);
}

.SkillCard-database {
    background-color: rgb(3, 146, 255);
}

.SkillCard-bottom {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    padding-top: 1px;
    padding-bottom: 1px;
    left: 0;
    bottom: 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    display: flex;
    flex-direction: column;
}

.SkillCard-title {
    font-weight: bold;
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 10px;
}

.SkillCard-stars {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    margin-bottom: 1px;
}

.SkillCard-star-icon {
    margin: 0;
    padding: 0;
    font-size: 10px;
    margin-right: 2px;
    color: rgb(255, 195, 0);
}

@media all and (max-width: 820px) {
    .SkillCard {
        width: 62px;
        height: 54px;
        margin: 5px;
        padding: 12px 29px;
    }

    .SkillCard-img {
        width: 62px;
        height: 60px;
    }

    .SkillCard-categories {
        width: 22px;
        max-height: 50px;
        top: 2px;
        right: 2px;
    }

    .SkillCard-backend,
    .SkillCard-frontend,
    .SkillCard-mobile,
    .SkillCard-database,
    .SkillCard-wrench {
        font-size: 10px;
        color: #fff;
        border-radius: 10px;
        width: 18px;
        height: 18px;
        margin-top: 2px;
    }

    .SkillCard-title {
        font-size: 10px;
    }

    .SkillCard-star-icon {
        font-size: 8px;
        margin-right: 2px;
    }
}
