.Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 100;
    background-color: rgb(44, 0, 85);
    height: 56px;
    color: #fff;
    position: sticky;
    top: 0;
}

.Header-profile-info-title {
    font-size: 12px;
}

.Header-avatar {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.Header-name {
    margin-left: 10px;
    font-size: 13px;
    font-weight: bold;
}

.Header-navigation {
    margin-left: auto;
    margin-right: auto;

    flex: 1;
    display: flex;
    justify-content: center;
}

.Header-social {
    width: 70px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Header-social-link {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    width: 36px;
}

.Header-social-icon {
    font-size: 24px;
}

.Header-social-icon:hover {
    font-size: 28px;
}

.Header-language {
    margin-right: 10px;
}

@media all and (max-width: 800px) {
    .Header-navigation {
        order: 1;
        margin-left: auto;
        margin-right: 0;
        flex: none;
    }

    .Header-avatar {
        order: 2;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        margin-left: 0;
    }

    .Header-avatar img {
        height: 30px;
        width: 30px;
    }

    .Header-name {
        margin-top: 4px;
        margin-left: 0;
        font-size: 12px;
    }

    .Header-language {
        order: 3;
        margin-right: 0;
    }

    .Header-social {
        display: none;
    }
}
