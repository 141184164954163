body {
    margin: 0;
    font-family: Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
}

p {
    margin: 0;
    line-height: 18px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateY(300px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateY(300px);
}

.carousel .control-dots .dot {
    opacity: 0.8;
}

.carousel.carousel-slider .control-arrow {
    opacity: 0.8;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #333;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #333;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    background-color: rgba(44, 0, 85, 0.3);
}

.carousel .slide {
    text-align: left;
}
