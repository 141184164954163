.ExperienceCard {
    height: 80px;
    margin: 10px 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 20px;
}

.ExperienceCard:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}

.ExperienceCard-left {
    margin: 10px;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ExperienceCard-right {
    margin: 10px;
    flex: 1;
    height: 80px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: center;
}

.ExperienceCard-img {
    object-fit: contain;
    width: 100%;
}

.ExperienceCard-img-round {
    border-radius: 40px;
}

.ExperienceCard-icon {
    font-size: 30px;
    color: #777;
}

.ExperienceCard-title {
    font-size: 13px;
    color: #888;
    font-weight: bold;
}

.ExperienceCard-company {
    font-size: 13px;
    color: #333;
    font-weight: bold;
    margin-top: 6px;
}

.ExperienceCard-period {
    font-size: 12px;
    color: #333;
    margin-top: 6px;
}

.ExperienceCard-right a {
    display: flex;
    text-decoration: none;
}

.ExperienceCard-right a:hover {
    text-decoration: underline;
}

@media all and (max-width: 990px) {
    .ExperienceCard {
        height: 90px;
        margin: 10px 5px;
        padding: 4px;
    }

    .ExperienceCard-left {
        margin: 5px;
        width: 50px;
        height: 90px;
    }

    .ExperienceCard-right {
        margin: 5px;
        height: 70px;
    }

    .ExperienceCard-icon {
        font-size: 20px;
    }

    .ExperienceCard-company {
        margin-top: 4px;
    }

    .ExperienceCard-period {
        margin-top: 4px;
    }
}

@media all and (max-width: 400px) {
    .ExperienceCard-title {
        font-size: 11px;
    }

    .ExperienceCard-company {
        font-size: 11px;
    }

    .ExperienceCard-period {
        font-size: 10px;
    }
}
