.HomeSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    margin: 0 6px 12px 6px;
    padding: 20px 0 20px;
}

.HomeSection-carousel {
    margin: 20px 8px 0 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    align-self: center;
    max-width: 350px;
}

.HomeSection-carousel-wrapper {
    height: 222px;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    cursor: pointer;
}

.HomeSection-carousel-title-wrapper {
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.HomeSection-carousel-title-A {
    background-color: rgb(199, 0, 57);
}

.HomeSection-carousel-title-B {
    background-color: rgb(3, 146, 255);
}

.HomeSection-carousel-title-C {
    background-color: rgba(255, 195, 0);
}

.HomeSection-carousel-icon-wrapper {
    margin: 7px;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #fff;
}

.HomeSection-carousel-icon {
    color: #333;
    font-size: 14px;
}

.HomeSection-carousel-title-text {
    color: #fff;
    font-weight: bold;
    font-size: 11px;
}

.HomeSection-carousel-img-wrapper {
    position: relative;
}

.HomeSection-carousel-img {
    object-fit: contain;
    height: 100%;
    position: relative;
}

.HomeSection-carousel-cover {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.01);
}

.HomeSection-profile {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 10px 10px;
}

.HomeSection-title {
    padding: 0 0 14px 0;
    font-size: 26px;
    text-align: center;
    color: #333;
    max-width: 440px;
    font-weight: bold;
}

.HomeSection-profile-img {
    border-radius: 101px;
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
    border: 2px solid #ccc;
}

.HomeSection-profile-name {
    color: #333;
    font-size: 16px;
    margin-bottom: 4px;
}

.HomeSection-profile-title {
    font-size: 14px;
    color: #aaa;
}

.HomeSection-profile,
.HomeSection-desc {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
}

.HomeSection-desc {
    border-left: 1px dashed #ccc;
}

.HomeSection-desc-title {
    padding-top: 12px;
    font-size: 18px;
    color: #999;
    font-weight: bold;
    padding: 0 10px;
    margin-bottom: 10px;
    line-height: 22px;
}

.HomeSection-desc-content > p {
    padding: 0 10px;
    font-size: 16px;
    text-align: justify;
    margin-bottom: 10px;
    line-height: 22px;
}

.HomeSection-desc-content > p:last-of-type {
    font-weight: bold;
}

.HomeSection-project {
    margin-top: 10px;
    background-color: rgb(44, 0, 85);
    border: none;
    padding: 8px 16px;
    color: #fff;
    font-weight: bold;
    border-radius: 45px;
    font-size: 12px;
    cursor: pointer;
    width: auto;
    align-self: center;
}

.HomeSection-project-icon {
    font-size: 12px;
    margin-right: 4px;
}

.HomeSection-project:hover {
    background-color: rgba(44, 0, 85, 0.8);
}

.HomeSection-separator {
    margin-top: 20px;
}

@media all and (max-width: 900px) {
    .HomeSection-desc-content > p {
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 20px;
    }

    .HomeSection-desc-title {
        font-size: 16px;
    }
}

@media all and (max-width: 760px) {
    .HomeSection {
        flex-direction: column;
        padding: 10px;
    }

    .HomeSection-profile-name,
    .HomeSection-profile-title {
        font-size: 14px;
    }

    .HomeSection-profile-img {
        border-radius: 60px;
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
    }

    .HomeSection-profile,
    .HomeSection-desc {
        padding: 10px;
    }

    .HomeSection-title {
        font-size: 20px;
        margin: 0 20px 0 20px;
    }

    .HomeSection-desc {
        border-left: none;
    }

    .HomeSection-desc-content {
        padding-top: 0;
    }

    .HomeSection-desc-title {
        font-size: 16px;
        text-align: center;
    }

    .HomeSection-desc-content > p {
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 20px;
    }

    .HomeSection-carousel {
        max-width: 300px;
    }

    .HomeSection-carousel-wrapper {
        height: 200px;
    }

    .HomeSection-carousel-title-text {
        font-size: 10px;
    }
}
