.ProjectModal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 400;
    width: 500px;
    display: flex;
    flex-direction: column;
    max-height: 600px;
}

.ProjectModal-Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 300;
}

.ProjectModal-Title {
    width: 100%;
    background-color: rgb(3, 146, 255);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ProjectModal-Title-A {
    background-color: rgb(199, 0, 57);
}

.ProjectModal-Title-B {
    background-color: rgb(3, 146, 255);
}

.ProjectModal-Title-C {
    background-color: rgb(255, 195, 0);
}

.ProjectModal-Title-Icon-Wrapper {
    margin-left: 7px;
    margin-right: 7px;
    margin-top: 7px;
    margin-bottom: 7px;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #fff;
}

.ProjectModal-Title-Icon {
    color: #333;
    font-size: 16px;
}

.ProjectModal-Title-Text {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
}

.ProjectModal-Close-Icon {
    justify-self: flex-end;
    margin-left: auto;
    margin-right: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
}

.ProjectModal-Content {
    overflow-y: scroll;
    padding: 10px 20px;
}

.ProjectModal-Content-Image {
    width: 100%;
    object-fit: contain;
}

.ProjectModal-Content-Label {
    color: #333;
    font-weight: bold;
    font-size: 14px;
    text-decoration: underline;
}

.ProjectModal-Content-Description {
    font-size: 14px;
    text-align: justify;
    color: rgb(51, 51, 51);
    margin-bottom: 20px;
    margin-top: 20px;
}

.ProjectModal-Content-List {
    counter-reset: desc;
    padding: 0 16px;
    list-style: none;
    margin-top: 20px;
    margin-bottom: 20px;
}

.ProjectModal-Content-List-Item {
    counter-increment: desc;
    margin-bottom: 10px;
    font-size: 14px;
    text-align: justify;
    color: rgb(51, 51, 51);
    line-height: 18px;
}

.ProjectModal-Content-List-Item:before {
    margin-right: 6px;
    content: counter(desc);
    background: rgb(44, 0, 85);
    border-radius: 100%;
    color: #fff;
    width: 18px;
    text-align: center;
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
}

.ProjectModal-Content-Remark {
    list-style: none;
    padding: 0 16px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.ProjectModal-Content-Remark-Item {
    margin-bottom: 10px;
    font-size: 14px;
    text-align: justify;
    color: rgb(51, 51, 51);
    line-height: 16px;
    position: relative;
}

.ProjectModal-Content-Remark-Item:before {
    position: relative;
    top: -1px;
    margin-right: 6px;
    content: '⊕';
    color: #888;
    font-size: 22px;
    vertical-align: top;
    margin-top: 0;
    padding: 0;
    display: inline-block;
    line-height: 16px;
}

.ProjectModal-Content-Tecnologies {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 0;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: center;
}

@media all and (max-width: 820px) {
    .ProjectModal {
        width: calc(100% - 20px);
        max-height: 500px;
    }

    .ProjectModal-Title-Text {
        font-size: 14px;
    }

    .ProjectModal-Close-Icon {
        font-size: 14px;
    }

    .ProjectModal-Content-Label {
        font-size: 12px;
    }

    .ProjectModal-Content-Description {
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .ProjectModal-Content-List {
        counter-reset: desc;
        padding: 0 6px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .ProjectModal-Content-List-Item {
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 16px;
    }

    .ProjectModal-Content-List-Item:before {
        border-radius: 100%;
        font-size: 12px;
        width: 16px;
    }

    .ProjectModal-Content-Remark {
        padding: 0 6px;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .ProjectModal-Content-Remark-Item {
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 16px;
    }

    .ProjectModal-Content-Remark-Item:before {
        margin-right: 6px;
        font-size: 18px;
        line-height: 16px;
    }

    .ProjectModal-Content-Tecnologies {
        padding: 4px 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
