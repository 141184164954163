.ProjectSection {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    margin: 0 6px 12px 6px;
    padding: 0 20px 0px;
}

.ProjectSection-title {
    margin-top: 14px;
    font-size: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
}

.ProjectSection-icon {
    font-size: 22px;
    margin-right: 10px;
}

.ProjectSection-desc {
    margin: 0 10px 10px;
    font-size: 16px;
    color: #333;
    text-align: justify;
}

.ProjectSection-Cards {
    margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.ProjectSection-Card {
    width: 260px;
    height: 320px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 8px;
}

.ProjectSection-Card-Head {
    height: 140px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.ProjectSection-Card-Head-A {
    background-color: rgb(199, 0, 57);
}

.ProjectSection-Card-Head-B {
    background-color: rgb(3, 146, 255);
}

.ProjectSection-Card-Head-C {
    background-color: rgb(255, 195, 0);
}

.ProjectSection-Card-Head-Icon-Wrapper {
    margin: 10px;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #fff;
}

.ProjectSection-Card-Head-Icon {
    color: #333;
    font-size: 44px;
}

.ProjectSection-Card-Title-Wrapper {
    height: 40px;
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
}

.ProjectSection-Card-Title {
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    font-size: 13px;
    color: #fff;
}

.ProjectSection-Card-Description {
    margin: 14px 10px 5px;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
    color: #333;
    padding-bottom: 5px;
}

.ProjectSection-Card-Technologies {
    flex: 1;
    padding: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.ProjectSection-Card-Technology {
    font-weight: bold;
    font-size: 9px;
    padding: 4px 6px;
    background-color: #aaa;
    border-radius: 20px;
    margin-top: 4px;
    height: 12px;
    margin-right: 4px;
}

.ProjectSection-Card-Technology a {
    text-decoration: none;
    color: #fff;
}

.ProjectSection-Card-Button-Wrapper {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: auto;
}

.ProjectSection-Card-Button {
    background-color: rgb(44, 0, 85);
    border: none;
    padding: 12px 16px;
    color: #fff;
    font-weight: bold;
    border-radius: 40px;
    font-size: 14px;
    cursor: pointer;
}

.ProjectSection-Card-Button:hover {
    background-color: rgba(44, 0, 85, 0.85);
}

.ProjectSection-contact {
    color: rgb(44, 0, 85);
    font-weight: bold;
    cursor: pointer;
}

@media all and (max-width: 820px) {
    .ProjectSection {
        padding: 4px 10px;
    }

    .ProjectSection-title {
        padding: 0 0 10px 0;
        font-size: 20px;
        margin-top: 6px;
    }

    .ProjectSection-icon {
        font-size: 18px;
        margin-right: 4px;
    }

    .ProjectSection-desc {
        padding: 0 4px 10px;
        margin: 0 10px;
        font-size: 14px;
    }

    .ProjectSection-Cards {
        margin: 0;
    }

    .ProjectSection-Card {
        width: 250px;
        height: 310px;
        margin: 12px 8px;
    }

    .ProjectSection-Card-Head {
        height: 110px;
    }

    .ProjectSection-Card-Head-Icon-Wrapper {
        margin: 10px;
        width: 80px;
        height: 80px;
    }

    .ProjectSection-Card-Head-Icon {
        font-size: 38px;
    }

    .ProjectSection-Card-Title-Wrapper {
        height: 32px;
        width: 100%;
    }

    .ProjectSection-Card-Title {
        font-size: 12px;
        text-align: left;
    }

    .ProjectSection-Card-Description {
        text-align: justify;
        margin: 10px 4px 10px;
        font-size: 12px;
        line-height: 16px;
    }

    .ProjectSection-Card-Button {
        margin-top: 10px;
        padding: 12px 16px;
        font-size: 12px;
    }
}
