.Navigation-btn,
.Navigation-toogle {
    display: none;
}

.Navigation-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.Navigation-item {
    width: 120px;
    text-align: center;
}

.Navigation-link {
    font-weight: bold;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    font-size: 12px;
}

.Navigation-icon {
    font-size: 12px;
    margin-right: 6px;
    color: inherit;
}

.Navigation-link:hover,
.Navigation-link:hover .Navigation-icon {
    font-size: 15px;
}

@media all and (max-width: 800px) {
    .Navigation-btn {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        cursor: pointer;
        position: relative;
        padding: 10px 20px;
        color: #fff;
    }

    .Navigation-icon-btn {
        font-size: 28px;
    }

    .Navigation-list {
        display: none;
        flex-direction: column;
        background: #2c0055;
        align-items: flex-start;
    }

    .Navigation-item {
        width: 100%;
        padding: 0;
        display: flex;
        text-align: left;
    }

    .Navigation-link {
        flex: 1;
        font-size: 12px;
        padding: 12px 20px;
    }

    .Navigation-icon {
        font-size: 16px;
        margin-right: 10px;
    }

    .Navigation-link:hover .Navigation-icon {
        font-size: 16px;
    }

    .Navigation-toogle:checked + .Navigation-list {
        display: flex;
        position: absolute;
        width: 100%;
        margin-top: 4px;
    }
}
