.ContactSection {
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    margin: 0 6px 0 6px;
    padding: 0 20px 0;
    background-color: #fff;
}

.ContactSection-title {
    margin-top: 14px;
    font-size: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
}

.ContactSection-icon {
    font-size: 22px;
    margin-right: 10px;
}

.ContactSection-wrapper {
    width: 500px;

    align-self: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    margin: 20px 0;
}

.ContactSection-head {
    align-self: center;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    background-color: rgb(44, 0, 85);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.ContactSection-head-icon {
    font-size: 42px;
    color: #fff;
}

.ContactForm {
    display: flex;
    flex-direction: column;
}

.ContactForm-desc {
    padding: 10px;
    color: #777;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    font-size: 14px;
}

.ContactForm-error {
    padding: 10px;
    font-weight: bold;
    margin: 0 10px 20px 10px;
    font-size: 12px;
    background-color: #da1414;
    color: #fff;
    border-radius: 2px;
    text-align: left;
    display: flex;
    align-items: center;
}

.ContactForm-error-icon {
    font-size: 20px;
    color: #fff;
    margin-right: 10px;
}

.ContactForm-group {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.ContactForm-label {
    padding-top: 4px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #888;
}

.ContactForm-text,
.ContactForm-textarea {
    font-size: 14px;

    color: #333;
    border: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-radius: 5px;
}

.ContactForm-textarea-counter {
    margin-top: 4px;
    margin-right: 2px;
    align-self: flex-end;
    font-size: 11px;
    color: #777;
}

.ContactForm-text {
    height: 36px;
    padding: 0 10px;
}

.ContactForm-textarea {
    height: 100px;
    padding: 10px;
}

.ContactForm-submit {
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: rgb(44, 0, 85);
    border: none;
    font-size: 14px;
    align-self: flex-end;
    width: 100px;
    margin: 10px;
}

.ContactSection-loading,
.ContactSection-confirm {
    align-self: center;
    justify-content: center;
    align-items: center;
    padding: 40px 70px;
    margin-top: 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
}

.ContactSection-loading span,
.ContactSection-confirm span {
    color: #333;
    font-size: 14px;
}

.ContactSection-confirm button {
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 30px;
    background-color: rgb(44, 0, 85);
    border: none;
    font-size: 14px;
    margin: 20px 10px 10px;
}

.ContactSection-confirm-icon {
    font-size: 28px;
    margin-top: 10px;
    color: #28a745;
}

@media all and (max-width: 820px) {
    .ContactSection {
        padding: 4px 10px;
    }

    .ContactSection-title {
        padding: 0 0 10px 0;
        font-size: 20px;
        margin-top: 6px;
    }

    .ContactSection-icon {
        font-size: 18px;
        margin-right: 4px;
    }

    .ContactSection-wrapper {
        padding: 20px 10px;
        margin: 10px 0 0;
        max-width: 300px;
    }

    .ContactSection-head {
        height: 80px;
        width: 80px;
        margin-bottom: 10px;
    }

    .ContactSection-head-icon {
        font-size: 34px;
    }

    .ContactForm-desc {
        padding: 10px 10px 0;
        margin-bottom: 20px;
        font-size: 12px;
    }

    .ContactForm-error {
        padding: 10px;
        margin: 0 10px 0px 10px;
        font-size: 10px;
    }

    .ContactForm-error-icon {
        font-size: 14px;
        margin-right: 4px;
    }

    .ContactForm-group {
        display: flex;
        flex-direction: column;
        padding: 6px 10px;
    }

    .ContactForm-label {
        padding-top: 4px;
        padding-bottom: 8px;
        font-size: 12px;
    }

    .ContactForm-text,
    .ContactForm-textarea {
        font-size: 12px;
    }

    .ContactForm-text {
        height: 26px;
        padding: 0 6px;
    }

    .ContactForm-textarea {
        height: 80px;
        padding: 6px;
    }

    .ContactForm-submit {
        padding: 10px 20px 10px;
        border-radius: 30px;

        font-size: 12px;
        width: 100px;
        margin: 10px 10px 0;
    }

    .ContactSection-loading,
    .ContactSection-confirm {
        padding: 20px 40px;
        margin-top: 20px;
    }

    .ContactSection-confirm button {
        padding: 10px 20px 10px;
        border-radius: 30px;
        font-size: 12px;
        margin: 30px 10px 10px;
    }
}
