.DropDown {
    display: inline-block;
    position: relative;
}

.DropDown-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: inline-block;
    padding: 6px 10px 0 10px;
}

.DropDown-header-opened {
    background-color: #fff;
    color: #777;
}

.DropDown-header-title {
    margin-right: 10px;
}

.DropDown-header-title,
.DropDown-header-icon {
    display: inline-block;
}

.DropDown-header-icon {
    font-size: 16px;
    color: inherit;
}

.DropDown-list {
    background-color: #fff;
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    box-sizing: border-box;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.DropDown-list-item {
    padding: 6px 10px 0 10px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.DropDown-list-item:hover {
    background-color: #ddd;
    cursor: pointer;
}

.DropDown-list-item:first-child {
    border-top: 1px solid #ccc;
}

.DropDown-list-item:last-child {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}
