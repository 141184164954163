.AboutSection {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    margin: 0 6px 12px 6px;
    padding: 0 20px 0px;
}

.AboutSection-main-title {
    margin-top: 14px;
    font-size: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
}

.AboutSection-icon {
    font-size: 20px;
    margin-right: 10px;
}

.AboutSection-title {
    margin-top: 10px;
    padding: 0 10px 0px 10px;
    font-size: 20px;
    text-align: left;
    color: #333;
}

.AboutSection-skills {
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
}

.AboutSection-skills-selection {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #ddd;
}

.AboutSection-skills-arrow {
    position: absolute;
    right: -21px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-size: 28px;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.AboutSection-skills-arrow-backend {
    color: rgb(44, 0, 85);
}

.AboutSection-skills-arrow-frontend {
    color: rgb(199, 0, 57);
}

.AboutSection-skills-arrow-database {
    color: rgb(3, 146, 255);
}

.AboutSection-skills-arrow-tools {
    color: rgb(255, 195, 0);
}

.AboutSection-desktop {
    display: block;
}

.AboutSection-mobile {
    display: none;
}

.AboutSection-skills-categories {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.AboutSection-skills-category-break {
    flex-basis: 100%;
    height: 0;
}

.AboutSection-skills-category-icon {
    font-size: 20px;
    margin-bottom: 5px;
}

.AboutSection-skills-category {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    width: 100px;
    height: 120px;
    padding: 0 10px;
    border-radius: 20px;
    margin: 10px;

    text-align: center;
    cursor: pointer;
}

.AboutSection-skills-category:hover .AboutSection-skills-category-icon {
    font-size: 24px;
}

.AboutSection-skills-category:hover {
    font-size: 14px;
    width: 110px;
    height: 130px;
    margin: 5px;
}
.AboutSection-skill-category {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #555;
    padding-left: 20px;
}

.AboutSection-skill-category-icon {
    color: #fff;
    font-weight: bold;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    font-size: 14px;
}

.AboutSection-skills-category-backend,
.AboutSection-skill-category-backend {
    background-color: rgb(44, 0, 85);
}

.AboutSection-skills-category-backend {
    box-shadow: 0 2px 4px 0 rgba(44, 0, 85, 0.8);
}

.AboutSection-skills-category:hover.AboutSection-skills-category-backend {
    background-color: rgba(44, 0, 85, 0.85);
}

.AboutSection-skills-category-frontend,
.AboutSection-skill-category-frontend {
    background-color: rgb(199, 0, 57);
}

.AboutSection-skills-category-frontend {
    box-shadow: 0 2px 4px 0 rgba(199, 0, 57, 0.8);
}

.AboutSection-skills-category:hover.AboutSection-skills-category-frontend {
    background-color: rgba(199, 0, 57, 0.85);
}

.AboutSection-skills-category-database,
.AboutSection-skill-category-database {
    background-color: rgb(3, 146, 255);
}

.AboutSection-skills-category-database {
    box-shadow: 0 2px 4px 0 rgba(3, 146, 255, 0.8);
}

.AboutSection-skills-category:hover.AboutSection-skills-category-database {
    background-color: rgba(3, 146, 255, 0.85);
}

.AboutSection-skills-category-tools,
.AboutSection-skill-category-tools {
    background-color: rgb(255, 195, 0);
}

.AboutSection-skills-category-tools {
    box-shadow: 0 2px 4px 0 rgba(255, 195, 0, 0.8);
}

.AboutSection-skills-category:hover.AboutSection-skills-category-tools {
    background-color: rgba(255, 195, 0, 0.85);
}

.AboutSection-skills-display {
    flex: 1;
}

.AboutSection-skills-selected {
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
    margin: 0px 20px 20px 40px;
    border-radius: 10px;
}

.AboutSection-skills-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

/**/

.AboutSection-parts {
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px;
}

.AboutSection-professional,
.AboutSection-formation {
    flex: 1;
}

.AboutSection-formation {
    border-left: 1px dashed #aaa;
    display: flex;
    flex-direction: column;
}

.AboutSection-professional-container,
.AboutSection-formation-container {
    display: flex;
    flex-direction: row;
}

.AboutSection-professional-graphics,
.AboutSection-formation-graphics {
    width: 40px;
    display: flex;
    flex-direction: column;
}

/*140height */
.AboutSection-professional-graphic,
.AboutSection-formation-graphic {
    position: relative;
    margin-left: 17px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 100px;
}

.AboutSection-professional-graphic {
    border-left: 1px solid rgb(44, 0, 85);
}

.AboutSection-professional-graphic-filled::after {
    background-color: rgb(44, 0, 85);
}

.AboutSection-professional-graphic-filled.AboutSection-professional-graphic::before {
    border-color: rgb(255, 255, 255);
}

.AboutSection-formation-graphic {
    border-left: 1px solid rgb(199, 0, 57);
}

.AboutSection-professional-graphic::after,
.AboutSection-formation-graphic::after {
    content: '';
    position: absolute;
    top: -32px;
    left: -12px;
    width: 20px;
    height: 20px;
    border-radius: 14px;
}

.AboutSection-professional-graphic::before,
.AboutSection-formation-graphic::before {
    content: '';
    position: absolute;
    z-index: 10;
    top: -29px;
    left: -5px;
    border-style: solid;
    border-width: 0 3px 3px 0;
    height: 12px;
    width: 6px;
    transform: rotate(45deg);
}

.AboutSection-professional-graphic::after {
    border: 2px solid rgb(44, 0, 85);
}

.AboutSection-professional-graphic::before {
    border-color: rgb(44, 0, 85);
}

.AboutSection-formation-graphic::after {
    background-color: rgb(199, 0, 57);
    border: 2px solid rgb(199, 0, 57);
}

.AboutSection-formation-graphic::before {
    border-color: rgb(255, 255, 255);
}

.AboutSection-professional-graphic:first-of-type,
.AboutSection-formation-graphic:first-of-type {
    margin-top: 90px;
}

.AboutSection-professional-graphic:last-of-type,
.AboutSection-formation-graphic:last-of-type {
    height: 0;
    margin-bottom: 0;
}

.AboutSection-professional-cards,
.AboutSection-formation-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.AboutSection-formation-part {
    margin-left: 15px;
}

.AboutSection-skills-levels {
    margin-left: 20px;
    margin-bottom: 20px;
}

.AboutSection-skills-levels-title {
    color: #333;
    font-size: 14px;
    margin-bottom: 4px;
}

.AboutSection-skills-level-icon {
    font-size: 12px;
    color: rgb(255, 195, 0);
    margin-right: 2px;
}

.AboutSection-skills-level-value {
    color: #555;
    font-size: 12px;
    margin-left: 6px;
    font-weight: bold;
}

.AboutSection-skills-levels-legends {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.AboutSection-skills-levels-legend {
    margin: 10px;
}

.AboutSection-knowledges {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px;
}

.AboutSection-knowledge {
    width: 210px;
    height: 220px;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    border-radius: 18px;
    background-color: #f1f9ff;
    margin: 10px;
    border: 1px dashed #bbb;
}

.AboutSection-knowledge-icon {
    font-size: 40px;
    color: #a9c1f7;
}

.AboutSection-knowledge-content {
    margin: 20px 0;
    text-align: center;
}

.AboutSection-knowledge-title {
    height: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.AboutSection-knowledge-title-text {
    font-size: 15px;
    font-weight: bold;
    color: #555;
}

.AboutSection-knowledge-list {
    list-style: none;
    font-size: 13px;
    color: #444;
    margin: 10px 10px 10px 10px;
    padding: 0;
}

.AboutSection-knowledge-item {
    margin: 15px 0;
}

@media all and (max-width: 990px) {
    .AboutSection {
        padding: 4px 10px;
    }

    .AboutSection-skills {
        flex-direction: column;
        padding: 10px 0px;
    }

    .AboutSection-title {
        padding: 0 0 10px 0;
        font-size: 18px;
        margin-top: 6px;
    }

    .AboutSection-icon {
        font-size: 16px;
        margin-right: 4px;
    }

    .AboutSection-main-title {
        margin-left: 0px;
        margin-right: 0px;
        font-size: 20px;
        padding-bottom: 16px;
    }

    .AboutSection-skills-selection {
        border-right: none;
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd;
    }

    .AboutSection-skills-arrow {
        right: auto;
        height: 26px;
        width: 26px;
        bottom: -13px;
        font-size: 18px;
    }

    .AboutSection-desktop {
        display: none;
    }

    .AboutSection-mobile {
        display: block;
    }

    .AboutSection-skills-category-break {
        flex-basis: inherit;
        height: inherit;
    }

    .AboutSection-skills-category {
        font-size: 10px;
        width: 50px;
        height: 70px;
        padding: 0 10px;
        border-radius: 10px;
        margin: 6px;
    }

    .AboutSection-skills-category-icon {
        font-size: 18px;
    }

    .AboutSection-skills-category:hover .AboutSection-skills-category-icon {
        font-size: 18px;
    }

    .AboutSection-skills-category:hover {
        font-size: 12px;
        width: 56px;
        height: 76px;
        margin: 3px;
    }

    .AboutSection-skills-display {
        padding-top: 30px;
    }

    .AboutSection-skills-selected {
        margin: 0px 0px 20px 0px;
        border-radius: 10px;
        padding-bottom: 20px;
    }

    .AboutSection-skill-category {
        padding-left: 20px;
        font-size: 13px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .AboutSection-skill-category-icon {
        border-radius: 12px;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        font-size: 12px;
    }

    /**/
    .AboutSection-parts {
        margin-top: 0;
        margin-bottom: 10px;
        flex-direction: column;
    }

    .AboutSection-formation {
        border: none;
    }

    .AboutSection-professional-graphics,
    .AboutSection-formation-graphics {
        width: 30px;
    }

    /*140height */
    .AboutSection-professional-graphic,
    .AboutSection-formation-graphic {
        margin-left: 6px;
        margin-top: 16px;
        margin-bottom: 16px;
        height: 85px;
    }

    .AboutSection-professional-graphic::after,
    .AboutSection-formation-graphic::after {
        top: -26px;
        left: -10px;
        width: 18px;
        height: 18px;
        border-radius: 11px;
        border-width: 1px;
    }

    .AboutSection-professional-graphic::before,
    .AboutSection-formation-graphic::before {
        top: -25px;
        left: -4px;
        border-width: 0 2px 2px 0;
        height: 12px;
        width: 6px;
    }

    .AboutSection-professional-graphic:first-of-type,
    .AboutSection-formation-graphic:first-of-type {
        margin-top: 78px;
    }

    .AboutSection-formation-part {
        margin-left: 0;
    }

    .AboutSection-skills-levels {
        margin-bottom: 0;
    }
}

@media all and (max-width: 700px) {
    .AboutSection-knowledge {
        width: 180px;
        height: 190px;
        padding: 15px;
        margin: 6px;
    }

    .AboutSection-knowledge-icon {
        font-size: 30px;
    }

    .AboutSection-knowledge-content {
        margin: 14px 0;
    }

    .AboutSection-knowledge-title {
        height: 32px;
    }

    .AboutSection-knowledge-title-text {
        font-size: 13px;
    }

    .AboutSection-knowledge-list {
        font-size: 11px;
        margin: 6px;
    }

    .AboutSection-knowledge-item {
        margin: 12px 0;
    }
}
